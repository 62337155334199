import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import Home from "../utilities/components/Home/Home";
import { useAuth } from "../utilities/hooks/auth-hook";
const Login = React.lazy(() => import("../auth/Login"));

const AdminRoutes = () => {
  //Hook
  const { Role } = useAuth();

  return (
    <Screen>
      <Routes>
        <Route path="/" element={<Home />}>
          {Role?.map((route, index) => (
            <Route key={index} path={route?.path} element={route?.element} />
          ))}
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Screen>
  );
};

export default AdminRoutes;

const Screen = styled.div`
  position: sticky;
  top: 0;
  background-color: blue;
`;
