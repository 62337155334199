import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Img,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  InputRightElement,
  Stack,
  Text,
  Textarea,
  Wrap,
} from "@chakra-ui/react";
import Select from "react-select";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { BiHide, BiShow } from "react-icons/bi";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { ClassicEditor } from "@ckeditor/ckeditor5-build-classic";

const CustomInput = ({
  field,
  title,
  htmlFor,
  type,
  error,
  touched,
  extra,
  options,
  form,
  myDefault,
  isMulti,
  maxDate,
  minDate,
  index,
  getOptionLabel,
  getOptionValue,
  disabled,
  placeholder,
}) => {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setConfirmPassword] = useState(false);
  const [showPassword, setPassword] = useState(false);
  return (
    <Box
      w={
        extra === "password"
          ? "300px"
          : { base: "100%", md: "70%", lg: "40%", xl: "40%", "2xl": "40%" }
      }
      pl={{ base: "10%", md: "0px" }}
    >
      <FormControl isInvalid={error && touched}>
        <Stack maxW="370px" spacing="0px">
          <FormLabel htmlFor={htmlFor}>
            <Text
              display={extra === "checkbox" || index >= 1 ? "none" : "flex"}
              w="200px"
            >
              {title}
            </Text>
          </FormLabel>
          {extra === "select" ? (
            <Wrap
              w={{
                base: "full",
                md: "440px",
                lg: "400px",
                "2xl": "450px",
              }}
            >
              <Box
                w={{
                  base: "80%",
                }}
              >
                <Select
                  isClearable={true}
                  isMulti={isMulti}
                  isDisabled={disabled}
                  styles={{
                    menuPortal: (base) => ({
                      zIndex: 999999,
                    }),
                  }}
                  // getOptionLabel={getOptionLabel}
                  // getOptionValue={getOptionValue}
                  getOptionLabel={
                    getOptionLabel
                      ? getOptionLabel
                      : (option) =>
                          option?.site_name
                            ? option?.site_name
                            : option?.item_name
                            ? option?.item_name
                            : option?.vendor_name
                            ? option?.vendor_name
                            : option?.customer_code
                            ? `${option?.customer_code} (${option?.customer_name})`
                            : option?.organization_code
                            ? `${option?.trade_name} (${option?.organization_code})`
                            : option?.plate_no
                            ? `${option?.plate_no} (${option?.client_code})`
                            : option?.full_name
                            ? option?.full_name
                            : option?.brand_name
                            ? option?.brand_name
                            : option?.model_name
                            ? option?.model_name
                            : option?.category
                            ? option?.category
                            : option?.code && htmlFor === "jobCard"
                            ? `JC-${option?.code}`
                            : option?.name
                  }
                  getOptionValue={
                    getOptionValue
                      ? getOptionValue
                      : (option) =>
                          option?.customer_code
                            ? option?.customer_code
                            : option?.organization_code
                            ? option?.organization_code
                            : option?.id
                  }
                  defaultValue={myDefault}
                  options={options}
                  onChange={(newSelection) => {
                    form?.setFieldValue(htmlFor, newSelection);
                  }}
                />
              </Box>
            </Wrap>
          ) : extra === "phone" ? (
            <InputGroup minW="300px">
              <InputLeftAddon
                fontSize={{
                  base: "18px",
                  sm: "14px",
                  md: "15px",
                  lg: "15px",
                  xl: "15px",
                  "2xl": "18px",
                }}
                children="+251"
                px={3}
              />
              <Input
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 9);
                }}
                style={{
                  borderTopRightRadius: "15px",
                  borderBottomRightRadius: "15px",
                }}
                fontSize={{
                  base: "18px",
                  sm: "14px",
                  md: "15px",
                  lg: "15px",
                  xl: "15px",
                  "2xl": "18px",
                }}
                {...field}
                type="number"
                className="form-control"
                placeholder="Phone Number"
              />
            </InputGroup>
          ) : extra === "Br" ? (
            <InputGroup minW="270px">
              <Input
                focusBorderColor="#2B6CB0"
                type="number"
                min="0"
                onChange={(e) => {
                  let val = parseInt(e.target.value, 10);
                  if (isNaN(val)) {
                    this.setState({ number: "" });
                  } else {
                    // is A Number
                    val = val >= 0 ? val : 0;
                    this.setState({ number: val });
                  }
                }}
                {...field}
                id={htmlFor}
                placeholder={title}
              />
              <InputRightAddon children="br" />
            </InputGroup>
          ) : extra === "date" ? (
            <Stack>
              {/* <Text fontWeight="bold">Date of birth</Text> */}
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  style={{
                    width: 230,
                    alignSelf: "start",
                  }}
                  maxDate={maxDate}
                  minDate={minDate}
                  id="date-picker-dialog"
                  format="MM/dd/yyyy"
                  placeholder=""
                  error={error && touched}
                  value={field?.value}
                  onChange={(newSelection) => {
                    form?.setFieldValue(field?.name, newSelection);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Stack>
          ) : extra === "password" ? (
            <InputGroup size="md">
              {htmlFor === "password" && (
                <Input
                  {...field}
                  id={htmlFor}
                  placeholder={title}
                  type={showPassword ? "text" : "password"}
                />
              )}
              {htmlFor === "confirmPassword" && (
                <Input
                  {...field}
                  id={htmlFor}
                  placeholder={title}
                  type={showConfirmPassword ? "text" : "password"}
                />
              )}{" "}
              {htmlFor === "oldPassword" && (
                <Input
                  {...field}
                  id={htmlFor}
                  placeholder={title}
                  type={showOldPassword ? "text" : "password"}
                />
              )}
              {htmlFor === "newPassword" && (
                <Input
                  {...field}
                  id={htmlFor}
                  placeholder={title}
                  type={showNewPassword ? "text" : "password"}
                />
              )}
              {htmlFor === "confirmNewPassword" && (
                <Input
                  {...field}
                  id={htmlFor}
                  placeholder={title}
                  type={showConfirmPassword ? "text" : "password"}
                />
              )}
              <InputRightElement width="4.5rem">
                {" "}
                {htmlFor === "password" && (
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={() => setPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <BiShow size="1.5rem" />
                    ) : (
                      <BiHide size={"1.4rem"} />
                    )}
                  </Button>
                )}
                {htmlFor === "oldPassword" && (
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={() => setShowOldPassword(!showOldPassword)}
                  >
                    {showOldPassword ? (
                      <BiShow size="1.5rem" />
                    ) : (
                      <BiHide size={"1.4rem"} />
                    )}
                  </Button>
                )}
                {htmlFor === "newPassword" && (
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  >
                    {showNewPassword ? (
                      <BiShow size="1.5rem" />
                    ) : (
                      <BiHide size={"1.4rem"} />
                    )}
                  </Button>
                )}
                {htmlFor === "confirmNewPassword" && (
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={() => setConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? (
                      <BiShow size="1.5rem" />
                    ) : (
                      <BiHide size={"1.4rem"} />
                    )}
                  </Button>
                )}{" "}
                {htmlFor === "confirmPassword" && (
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={() => setConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? (
                      <BiShow size="1.5rem" />
                    ) : (
                      <BiHide size={"1.4rem"} />
                    )}
                  </Button>
                )}
              </InputRightElement>
            </InputGroup>
          ) : extra === "checkbox" ? (
            <Stack w={"350px"} justify={"center"}>
              <Checkbox
                defaultIsChecked={field?.value ? true : false}
                {...field}
              >
                {title}
              </Checkbox>
            </Stack>
          ) : extra === "desc" ? (
            <Textarea
              h="200px"
              w={{ base: "100%", lg: "380px" }}
              {...field}
              id={htmlFor}
              placeholder={title}
              type={type}
            />
          ) : extra === "ckeditor" ? (
            <Stack
              w={{
                base: "300px",
                sm: "450px",
                md: "550px",
                lg: "540px",
                xl: "550px",
              }}
            >
              <CKEditor
                editor={ClassicEditor}
                data={field?.value}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  form?.setFieldValue(htmlFor, editor.getData());
                }}
                onBlur={(event, editor) => {}}
                onFocus={(event, editor) => {}}
              />
            </Stack>
          ) : (
            <Input
              w={{ base: "280px", lg: "320px" }}
              {...field}
              id={htmlFor}
              placeholder={placeholder ?? title}
              type={type}
              disabled={disabled}
            />
          )}
          {error && touched && (
            <Text w="300px" noOfLines={1} color="red">
              {error}
            </Text>
          )}
        </Stack>
      </FormControl>
    </Box>
  );
};
export default CustomInput;
