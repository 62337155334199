import { Center } from "@chakra-ui/react";
import React from "react";
import { PulseLoader } from "react-spinners";

export default function PulseLoaderComp({ h }) {
  return (
    <Center h={h}>
      <PulseLoader size={15} color="#385898" />
    </Center>
  );
}
