import { Stack, VStack } from "@chakra-ui/react";
import React from "react";
import LogoComponent from "./Logo";
import SideButton from "./SideButton";
import { Logo } from "../../utilities/components/Logo";
import { Link } from "react-router-dom";
import { useAuth } from "../hooks/auth-hook";

export default function SideBar() {
  //Hook
  const { Role } = useAuth();

  //Jsx
  return (
    <Stack
      h={"98vh"}
      shadow={"sm"}
      borderRadius={7}
      px={"2"}
      py={"3"}
      direction={"column"}
      bg={"white"}
      minW={{ base: "100%", lg: "100%", xl: "13%", "2xl": "15%" }}
      maxW={{ base: "100%", lg: "100%", xl: "13%", "2xl": "15%" }}
    >
      <Link to="/">
        <Logo title="AMWE" />
      </Link>
      <VStack pt={2} alignItems={"flex-start"} spacing={3}>
        {Role?.map((role, index) => {
          return (
            <SideButton
              key={index}
              enabled={role?.sideBarVisible}
              to={role?.path}
              name={role?.name}
            >
              {role?.icon}
            </SideButton>
          );
        })}
      </VStack>
    </Stack>
  );
}
