import { Settings } from "@material-ui/icons";
import React from "react";
import { AiFillCar, AiOutlineStock } from "react-icons/ai";
import { BiPurchaseTagAlt, BiSitemap, BiTransfer } from "react-icons/bi";
import { BsPersonFill, BsShuffle } from "react-icons/bs";
import { FaSitemap } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { GrDocument } from "react-icons/gr";
import {
  MdAssignmentInd,
  MdDashboard,
  MdEngineering,
  MdWork,
} from "react-icons/md";
import { RiCustomerService2Fill } from "react-icons/ri";
import StockTranfer from "../Pages/storeManager/stock/tranfer/Tranfer";
const ManageAllTestDrivers = React.lazy(() =>
  import("../Pages/customerService/testDrive/ManageAllTestDrivers")
);
const ForemanWeeklyWorkLoad = React.lazy(() =>
  import("../Pages/technicalHead/foreman/WeeklyWorkload/ForemanWeeklyWorkLoad")
);
const DetailJobCardPage = React.lazy(() =>
  import("../Pages/customerService/JobCard/DetailJobCard/DetailJobCardPage")
);

const UnpaidCashCollection = React.lazy(() =>
  import("../Pages/customerService/unpaidCashCollection/UnpaidCashCollection")
);

const AddJobCard = React.lazy(() =>
  import("../Pages/customerService/JobCard/AddJobCard/AddJobCard")
);
const CashCollection = React.lazy(() =>
  import("../Pages/customerService/cashCollection/CashCollection")
);
const DetailArchiveJobCard = React.lazy(() =>
  import(
    "../Pages/customerService/archiveJobCard/detailArchiveJobCard/DetailArchiveJobCard"
  )
);
const ReportManagment = React.lazy(() =>
  import("../Pages/customerService/report/ReportManagment")
);
const LaborManagment = React.lazy(() =>
  import("../Pages/labour/LaborManagment")
);

const ComplaintManagment = React.lazy(() =>
  import("../Pages/customerService/complaint/ComplaintManagment")
);

const PurchaseManagment = React.lazy(() =>
  import("../Pages/storeManager/purchase/PurchaseManagment")
);

const StockManagment = React.lazy(() =>
  import("../Pages/storeManager/stock/StockManagment")
);
const SiteManagment = React.lazy(() =>
  import("../Pages/storeManager/sites/SiteManagment")
);

const PurchaserVendorManagment = React.lazy(() =>
  import("../Pages/storeManager/vendors/PurchaserVendorManagment")
);
const ManageAllTechnians = React.lazy(() =>
  import("../Pages/customerService/technicians/ManageAllTechnians")
);
const ShuffleForeman = React.lazy(() =>
  import("../Pages/customerService/technicians/shuffleForeman/ShuffleForman")
);
const ForemanMonthlyWorkLoad = React.lazy(() =>
  import(
    "../Pages/technicalHead/foreman/MonthlyWorkload/ForemanMonthlyWorkLoad"
  )
);
const TechnicianMonthlyWorkLoad = React.lazy(() =>
  import("../Pages/foreman/TechnicianMonthlyWorkLoad")
);
const ItemManagment = React.lazy(() =>
  import("../Pages/storeManager/items/ItemManagment")
);
const SparePartsRequisition = React.lazy(() =>
  import("../Pages/foreman/Requisition/SparePartsRequisition")
);
const Dashboard = React.lazy(() =>
  import("../Pages/customerService/dasboard/Dashboard")
);
const ManageAllCustomers = React.lazy(() =>
  import("../Pages/customerService/customers/ManageCustomers")
);
const AddCustomer = React.lazy(() =>
  import("../Pages/customerService/customers/components/AddCustomer")
);
const EditCustomer = React.lazy(() =>
  import("../Pages/customerService/customers/components/EditCustomer")
);
const ManageVechicles = React.lazy(() =>
  import("../Pages/customerService/vehicles/ManageVechicles")
);
const ManageServices = React.lazy(() =>
  import("../Pages/customerService/services/ManageServices")
);
const Manageunit = React.lazy(() =>
  import("../Pages/customerService/services/unit/Unit")
);
const ManageAllUsers = React.lazy(() =>
  import("../Pages/customerService/users/ManageAllUsers")
);

const EditCustomerVehicle = React.lazy(() =>
  import("../Pages/customerService/customers/components/EditCustomerVehicle")
);
const AddCustomerVehicle = React.lazy(() =>
  import("../Pages/customerService/customers/components/AddCustomerVehicle")
);
const AddVehicle = React.lazy(() =>
  import("../Pages/customerService/vehicles/components/AddVehicle")
);
const AddOrganization = React.lazy(() =>
  import("../Pages/customerService/customers/components/AddOrganization")
);
const EditOrganization = React.lazy(() =>
  import("../Pages/customerService/customers/components/EditOrganization")
);
const ManageJobCards = React.lazy(() =>
  import("../Pages/customerService/JobCard/JobCardList/ManageJobCards")
);

const THManageJobCards = React.lazy(() =>
  import("../Pages/technicalHead/jobCard/THManageJobCards")
);

const ArchiveJobCards = React.lazy(() =>
  import(
    "../Pages/customerService/archiveJobCard/archiveJobCardList/ArchiveJobCards"
  )
);
const WorkshopReport = React.lazy(() =>
  import("../Pages/workshop/workshopReport")
);
const UtilityManagement = React.lazy(() =>
  import("../Pages/utilityManagement/UtilityItems")
);

// AdminService -> ADMINAUTH
export const ADMINAUTH = [
  {
    path: "/",
    element: <Dashboard />,
    name: "Dashboard",
    icon: <MdDashboard size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/admin/Customer",
    element: <ManageAllCustomers />,
    name: "Customer",
    icon: <RiCustomerService2Fill size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/admin/Vehicle",
    element: <ManageVechicles />,
    name: "Vehicle",
    icon: <AiFillCar size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/admin/Service",
    element: <ManageServices />,
    name: "Service",
    icon: <AiFillCar size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/item",
    element: <ItemManagment />,
    name: "Items",
    icon: <FaSitemap size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/admin/Users",
    element: <ManageAllUsers />,
    name: "Users",
    icon: <FiUsers size={"1.5em"} />,
    sideBarVisible: true,
  },
  // {
  //   path: "/admin/unit",
  //   element: <Manageunit />,
  //   name: "Unit",
  //   icon: <AiOutlineDeploymentUnit size={"1.5em"} />,
  //   sideBarVisible: true,
  // },
  {
    path: "/admin/Technicans",
    element: <ManageAllTechnians />,
    name: "Technians",
    icon: <BsPersonFill size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/admin/Test-drivers",
    element: <ManageAllTestDrivers />,
    name: "Test Drivers",
    icon: <BsPersonFill size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/admin/Customer/add-customer",
    element: <AddCustomer />,
    sideBarVisible: false,
  },
  {
    path: "/admin/Customer/add-vehicle(:clientCode)",
    element: <AddCustomerVehicle />,
    sideBarVisible: false,
  },
  {
    path: "/admin/Customer/edit-vehicle(:clientCode,:vehicleId)",
    element: <EditCustomerVehicle />,
    sideBarVisible: false,
  },
  {
    path: "/admin/JobCard/detail(:id)",
    element: <DetailJobCardPage />,
    sideBarVisible: false,
  },
  {
    path: "/admin/Vehicle/add-vehicle",
    element: <AddVehicle />,
    sideBarVisible: false,
  },
  {
    path: "/admin/Vehicle/edit-vehicle(:clientCode,:vehicleId)",
    element: <EditCustomerVehicle />,
    sideBarVisible: false,
  },
  {
    path: "/admin/Customer/add-organization",
    element: <AddOrganization />,
    sideBarVisible: false,
  },
  {
    path: "/admin/Customer/edit-customer(:id)",
    element: <EditCustomer />,
    sideBarVisible: false,
  },
  {
    path: "/admin/Customer/edit-organization(:id)",
    element: <EditOrganization />,
    sideBarVisible: false,
  },
  // {
  //   path: "/FollowUp",
  //   element: <FollowUp />,
  //   name: "Follow Up",
  //   icon: <RiChatFollowUpFill size={"1.5em"} />,
  //   sideBarVisible: true,
  // },

  {
    path: "/admin/Vendor",
    element: <PurchaserVendorManagment />,
    name: "Vendor/Purchaser",
    icon: <BiPurchaseTagAlt size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/admin/Site",
    element: <SiteManagment />,
    name: "Site",
    icon: <BiSitemap size={"1.5em"} />,
    sideBarVisible: true,
  },

  {
    path: "/admin/JobCard",
    element: <ManageJobCards />,
    name: "Job Card",
    icon: <MdWork size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/admin/JobCard/:id",
    element: <ManageJobCards />,
    sideBarVisible: false,
  },
  {
    path: "/admin/JobCard/detail(:id)",
    element: <DetailJobCardPage />,
    sideBarVisible: false,
  },
  {
    path: "/admin/archive-job-card",
    element: <ArchiveJobCards />,
    name: "Archive Job Card",
    icon: <MdWork size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/admin/archive-job-card/:id",
    element: <DetailArchiveJobCard />,
    sideBarVisible: false,
  },
  {
    path: "/Customer-service/archive-job-card/:id",
    element: <ManageJobCards />,
    sideBarVisible: false,
  },

  {
    path: "/admin/Report",
    element: <ReportManagment />,
    name: "Reports",
    icon: <GrDocument size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/admin/Report/:id",
    element: <ReportManagment />,
    sideBarVisible: false,
  },
  {
    path: "/workshop",
    element: <WorkshopReport />,
    name: "Workshop",
    icon: <MdWork size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/admin/utils",
    element: <UtilityManagement />,
    name: "Utils",
    icon: <Settings size={"1.5em"} />,
    sideBarVisible: true,
  },
];

// LeadCustomerService -> LCSAUTH
export const LCSAUTH = [
  {
    path: "/",
    element: <Dashboard />,
    name: "Dashboard",
    icon: <MdDashboard size={"1.5em"} />,
    sideBarVisible: true,
  },

  {
    path: "/Customer-service/Customer",
    element: <ManageAllCustomers />,
    name: "Customer",
    icon: <RiCustomerService2Fill size={"1.5em"} />,
    sideBarVisible: true,
  },

  {
    path: "/Customer-service/Vehicle",
    element: <ManageVechicles />,
    name: "Vehicle",
    icon: <AiFillCar size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/Customer-service/Customer/add-customer",
    element: <AddCustomer />,
    sideBarVisible: false,
  },
  {
    path: "/Customer-service/Customer/add-vehicle(:clientCode)",
    element: <AddCustomerVehicle />,
    sideBarVisible: false,
  },
  {
    path: "/Customer-service/Customer/edit-vehicle(:clientCode,:vehicleId)",
    element: <EditCustomerVehicle />,
    sideBarVisible: false,
  },
  {
    path: "/Customer-service/Customer/add-vehicle(:clientCode)",
    element: <AddCustomerVehicle />,
    sideBarVisible: false,
  },
  {
    path: "/Customer-service/Customer/edit-vehicle(:clientCode,:vehicleId)",
    element: <EditCustomerVehicle />,
    sideBarVisible: false,
  },
  {
    path: "/Customer-service/Vehicle/add-vehicle",
    element: <AddVehicle />,
    sideBarVisible: false,
  },
  {
    path: "/Customer-service/Vehicle/edit-vehicle(:clientCode,:vehicleId)",
    element: <EditCustomerVehicle />,
    sideBarVisible: false,
  },
  {
    path: "/Customer-service/Customer/add-organization",
    element: <AddOrganization />,
    sideBarVisible: false,
  },
  {
    path: "/Customer-service/Customer/edit-customer(:id)",
    element: <EditCustomer />,
    sideBarVisible: false,
  },
  {
    path: "/Customer-service/Customer/edit-organization(:id)",
    element: <EditOrganization />,
    sideBarVisible: false,
  },
  // {
  //   path: "/FollowUp",
  //   element: <FollowUp />,
  //   name: "Follow Up",
  //   icon: <RiChatFollowUpFill size={"1.5em"} />,
  //   sideBarVisible: true,
  // },

  {
    path: "/Customer-service/JobCard",
    element: <ManageJobCards />,
    name: "Job Card",
    icon: <MdWork size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/Customer-service/JobCard/:id",
    element: <ManageJobCards />,
    sideBarVisible: false,
  },
  {
    path: "/Customer-service/JobCard/detail(:id)",
    element: <DetailJobCardPage />,
    sideBarVisible: false,
  },
  {
    path: "/Customer-service/JobCard/add-jobcard",
    element: <AddJobCard />,
    sideBarVisible: false,
  },
  {
    path: "/Customer-service/archive-job-card",
    element: <ArchiveJobCards />,
    name: "Archive Job Card",
    icon: <MdWork size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/Customer-service/archive-job-card/:id",
    element: <DetailArchiveJobCard />,
    sideBarVisible: false,
  },
  {
    path: "/Customer-service/Report",
    element: <ReportManagment />,
    name: "Reports",
    icon: <GrDocument size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/Customer-service/Report/:id",
    element: <ReportManagment />,
    sideBarVisible: false,
  },
  {
    path: "/Customer-service/JobCard-Cash-collection",
    element: <CashCollection />,
    name: "Cash Collection",
    icon: <MdWork size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/Customer-service/unpaid-job-cards",
    element: <UnpaidCashCollection />,
    name: "Unpaid Job Cards",
    icon: <MdWork size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/Customer-service/JobCard-Cash-collection/:id",
    element: <CashCollection />,
    sideBarVisible: false,
  },
  {
    path: "/Complaint-Managment",
    element: <ComplaintManagment />,
    name: "Complaint Managment",
    icon: <MdDashboard size={"1.5em"} />,
    sideBarVisible: true,
  },
];

// CustomerServiceAgent -> CSAUTH
export const CSAUTH = [
  {
    path: "/",
    element: <Dashboard />,
    name: "Dashboard",
    icon: <MdDashboard size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/Customer-service/Customer",
    element: <ManageAllCustomers />,
    name: "Customer",
    icon: <RiCustomerService2Fill size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/Customer-service/Customer/add-vehicle(:clientCode)",
    element: <AddCustomerVehicle />,
    sideBarVisible: false,
  },
  {
    path: "/Customer-service/Customer/edit-vehicle(:clientCode,:vehicleId)",
    element: <EditCustomerVehicle />,
    sideBarVisible: false,
  },
  {
    path: "/Customer-service/Vehicle",
    element: <ManageVechicles />,
    name: "Vehicle",
    icon: <AiFillCar size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/Customer-service/Customer/add-customer",
    element: <AddCustomer />,
    sideBarVisible: false,
  },
  {
    path: "/Customer-service/Customer/add-vehicle(:clientCode)",
    element: <AddCustomerVehicle />,
    sideBarVisible: false,
  },
  {
    path: "/Customer-service/Customer/edit-vehicle(:clientCode,:vehicleId)",
    element: <EditCustomerVehicle />,
    sideBarVisible: false,
  },
  {
    path: "/Customer-service/Vehicle/add-vehicle",
    element: <AddVehicle />,
    sideBarVisible: false,
  },
  {
    path: "/Customer-service/Vehicle/edit-vehicle(:clientCode,:vehicleId)",
    element: <EditCustomerVehicle />,
    sideBarVisible: false,
  },
  {
    path: "/Customer-service/Customer/add-organization",
    element: <AddOrganization />,
    sideBarVisible: false,
  },
  {
    path: "/Customer-service/Customer/edit-customer(:id)",
    element: <EditCustomer />,
    sideBarVisible: false,
  },
  {
    path: "/Customer-service/Customer/edit-organization(:id)",
    element: <EditOrganization />,
    sideBarVisible: false,
  },
  {
    path: "/Customer-service/JobCard",
    element: <ManageJobCards />,
    name: "Job Card",
    icon: <MdWork size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/Customer-service/JobCard/:id",
    element: <ManageJobCards />,
    sideBarVisible: false,
  },
  {
    path: "/Customer-service/JobCard/add-jobcard",
    element: <AddJobCard />,
    sideBarVisible: false,
  },
  {
    path: "/Customer-service/archive-job-card",
    element: <ArchiveJobCards />,
    name: "Archive Job Card",
    icon: <MdWork size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/Customer-service/JobCard/detail(:id)",
    element: <DetailJobCardPage />,
    sideBarVisible: false,
  },
  {
    path: "/Customer-service/archive-job-card/:id",
    element: <DetailArchiveJobCard />,
    sideBarVisible: false,
  },
  {
    path: "/Customer-service/Report",
    element: <ReportManagment />,
    name: "Reports",
    icon: <GrDocument size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/Customer-service/Report/:id",
    element: <ReportManagment />,
    sideBarVisible: false,
  },
  {
    path: "/Customer-service/JobCard-Cash-collection",
    element: <CashCollection />,
    name: "Cash Collection",
    icon: <MdWork size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/Customer-service/JobCard-Cash-collection/:id",
    element: <CashCollection />,
    sideBarVisible: false,
  },
  {
    path: "/Complaint-Managment",
    element: <ComplaintManagment />,
    name: "Complaint Managment",
    icon: <MdDashboard size={"1.5em"} />,
    sideBarVisible: true,
  },
];

// ClientPurchaseRequestagent -> CPAUTH
export const CPAUTH = [
  {
    path: "/",
    element: <Dashboard />,
    name: "Dashboard",
    icon: <MdDashboard size={"1.5em"} />,
    sideBarVisible: true,
  },
];

// TechnicalHead -> THAUTH
export const THAUTH = [
  {
    path: "/Technical-head/JobCard",
    element: <ManageJobCards />,
    name: "Job Card",
    icon: <MdWork size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/Technical-head/JobCard/:id",
    element: <ManageJobCards />,
    sideBarVisible: false,
  },
  {
    path: "/Technical-head/JobCard/detail(:id)",
    element: <DetailJobCardPage />,
    sideBarVisible: false,
  },
  {
    path: "/Technical-head/assign-foreman",
    element: <THManageJobCards />,
    name: "Assign Foreman",
    icon: <MdAssignmentInd size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/Technical-head/shuffle-technician",
    element: <ManageAllTechnians />,
    name: "Shuffle Technician",
    icon: <BsShuffle size={"1.5em"} />,
    sideBarVisible: true,
  },
  // {
  //   path: `/Technical-head/ForemanWorkLoad`,
  //   element: <ForemanMonthlyWorkLoad />,
  //   name: "Foreman Workload",
  //   icon: <MdWork size={"1.5em"} />,
  //   sideBarVisible: true,
  // },
  {
    path: "/Technical-head/shuffle-foreman",
    element: <ShuffleForeman />,
    name: "Shuffle foreman",
    icon: <BsShuffle size={"1.5em"} />,
    sideBarVisible: true,
  },

  {
    path: "/Technical-head/ForemanWorkLoad/:month/:year",
    element: <ForemanMonthlyWorkLoad />,
    sideBarVisible: false,
  },
  {
    path: `/Technical-head/Weekly-ForemanWorkLoad`,
    element: <ForemanWeeklyWorkLoad />,
    name: "Foreman Weekly Workload",
    icon: <MdWork size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/Technical-head/Weekly-ForemanWorkLoad/:startDate/:endDate",
    element: <ForemanWeeklyWorkLoad />,
    sideBarVisible: false,
  },
  {
    path: `/Technical-head/Monthly-ForemanWorkLoad`,
    element: <ForemanMonthlyWorkLoad />,
    name: "Foreman Monthly Workload",
    icon: <MdWork size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/Technical-head/Monthly-ForemanWorkLoad/:month/:year",
    element: <ForemanMonthlyWorkLoad />,
    sideBarVisible: false,
  },
  {
    path: "/Technical-head/archive-job-card",
    element: <ArchiveJobCards />,
    name: "Archive Job Card",
    icon: <MdWork size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/Technical-head/archive-job-card/:id",
    element: <DetailArchiveJobCard />,
    sideBarVisible: false,
  },
  {
    path: "/workshop",
    element: <WorkshopReport />,
    name: "Workshop",
    icon: <MdWork size={"1.5em"} />,
    sideBarVisible: true,
  },
];

// Foreman -> FAUTH
export const FAUTH = [
  {
    path: "/",
    element: <ManageJobCards />,
    sideBarVisible: false,
  },
  {
    path: "/Foreman/JobCard",
    element: <ManageJobCards />,
    name: "Job Card",
    icon: <MdWork size={"1.5em"} />,
    sideBarVisible: true,
  },
  // new route for foreman
  {
    path: `/Store-Manager/Requisition`,
    element: <SparePartsRequisition />,
    name: "Requisition",
    icon: <MdWork size={"1.5em"} />,
    sideBarVisible: true,
  },

  {
    path: "/Foreman/JobCard/:id",
    element: <ManageJobCards />,
    sideBarVisible: false,
  },
  {
    path: "/Foreman/JobCard/detail(:id)",
    element: <DetailJobCardPage />,
    sideBarVisible: false,
  },
  {
    path: `/Foreman/TechnicianWorkLoad`,
    element: <TechnicianMonthlyWorkLoad />,
    name: "Technician Workload",
    icon: <MdWork size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/Foreman/TechnicianWorkLoad/:month/:year",
    element: <TechnicianMonthlyWorkLoad />,
    sideBarVisible: false,
  },
  {
    path: "/workshop",
    element: <WorkshopReport />,
    name: "Workshop",
    icon: <MdWork size={"1.5em"} />,
    sideBarVisible: true,
  },
];

// Store Manager -> SMAUTH
export const SMAUTH = [
  {
    path: "/",
    element: <ItemManagment />,
    name: "Items",
    icon: <FaSitemap size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/Store-Manager/JobCard",
    element: <ManageJobCards />,
    name: "Job Card",
    icon: <MdWork size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: `/Store-Manager/Requisition`,
    element: <SparePartsRequisition />,
    name: "Requisition",
    icon: <MdWork size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: `/Store-Manager/transfer`,
    element: <StockTranfer />,
    name: "Tranfer",
    icon: <BiTransfer size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/Store-Manager/JobCard/:id",
    element: <ManageJobCards />,
    sideBarVisible: false,
  },
  {
    path: "/Store-Manager/JobCard/detail(:id)",
    element: <DetailJobCardPage />,
    sideBarVisible: false,
  },
  {
    path: "/Store-Manager/Stock",
    element: <StockManagment />,
    name: "Stock",
    icon: <AiOutlineStock size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/Store-Manager/Purchase",
    element: <PurchaseManagment />,
    name: "Purchase",
    icon: <BiPurchaseTagAlt size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/Customer-service/Vendor",
    element: <PurchaserVendorManagment />,
    name: "Vendor",
    icon: <BiPurchaseTagAlt size={"1.5em"} />,
    sideBarVisible: true,
  },
];

// TimeCoordinator -> TCAUTH
export const TCAUTH = [
  {
    path: "/",
    element: <ManageJobCards />,
    sideBarVisible: false,
  },
  {
    path: "/Time-coordinator/JobCard",
    element: <ManageJobCards />,
    name: "Job Card",
    icon: <MdWork size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/Time-coordinator/JobCard/:id",
    element: <ManageJobCards />,
    sideBarVisible: false,
  },
  {
    path: "/Time-coordinator/JobCard/detail(:id)",
    element: <DetailJobCardPage />,
    sideBarVisible: false,
  },
];

// Purchasers -> PAUTH
export const PAUTH = [
  {
    path: "/",
    element: <Dashboard />,
    name: "Dashboard",
    icon: <MdDashboard size={"1.5em"} />,
    sideBarVisible: true,
  },
];

// Labor Manager -> LMAUTH
export const LMAUTH = [
  {
    path: "/",
    element: <Dashboard />,
    name: "Dashboard",
    icon: <MdDashboard size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/Labor-manager/JobCard",
    element: <ManageJobCards />,
    name: "Job Card",
    icon: <MdWork size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/Labor-manager/JobCard/:id",
    element: <ManageJobCards />,
    sideBarVisible: false,
  },
  {
    path: "/Labor-manager/Labor",
    element: <LaborManagment />,
    name: "Organization Labor",
    icon: <MdEngineering size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/Labor-manager/Labor/:id",
    element: <LaborManagment />,
    sideBarVisible: false,
  },
  {
    path: "/Labor-manager/Vehicle",
    element: <ManageVechicles />,
    name: "Vehicle",
    icon: <AiFillCar size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/Labor-manager/Service",
    element: <ManageServices />,
    name: "Service",
    icon: <AiFillCar size={"1.5em"} />,
    sideBarVisible: true,
  },
];

// FinanceAgent -> FAAUTH
export const FAAUTH = [
  {
    path: "/",
    element: <Dashboard />,
    name: "Dashboard",
    icon: <MdDashboard size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/Finance-agent/JobCard",
    element: <ManageJobCards />,
    name: "Job Card Costs",
    icon: <MdWork size={"1.5em"} />,
    sideBarVisible: true,
  },
  {
    path: "/Finance-agent/JobCard/:id",
    element: <ManageJobCards />,
    sideBarVisible: false,
  },
  {
    path: "/Finance-agent/JobCard/detail(:id)",
    element: <DetailJobCardPage />,
    sideBarVisible: false,
  },
];
