import { useAuth } from "./utilities/hooks/auth-hook";
import ScrollToTop from "./utilities/functions/ScrollToTop";
import { Center } from "@chakra-ui/react";
import { Suspense } from "react";
import NoAuthRoutes from "./routes/NoAuthRoutes";
import AdminRoutes from "./routes/AdminRoutes";
import PulseLoaderComp from "./utilities/components/queryMutatuinStates/PulseLoaderComp";

function App() {
  //Hook
  const { type, token, Role, user, checked, login, logout } = useAuth();

  function RoutesComp() {
    if (token && !!Role) {
      return <AdminRoutes />;
    } else if (checked && !token) {
      return <NoAuthRoutes />;
    }
  }
  //  console log new date is smaller than 5:30 pm

  // console.log(new Date().getHours() < 17);
  // console.log(new Date().getHours() < 8);
  // console.log(new Date().getHours() < 19);

  console.log("sadas", new Date()?.toLocaleString()?.split(",")[1]);

  return (
    <>
      <ScrollToTop />
      <Suspense
        fallback={
          <Center alignSelf={"center"} minH="500px">
            <PulseLoaderComp h={"90vh"} />
          </Center>
        }
      >
        {checked ? <RoutesComp /> : <PulseLoaderComp h={"90vh"} />}
      </Suspense>
    </>
  );
}

export default App;
