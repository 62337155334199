import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/auth-hook";

export default function BreadCrumb() {
  //Hook
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { type } = useAuth();

  //Function
  const path = pathname.split("/").filter((x) => x);

  //JSx
  return (
    <div>
      <Breadcrumb
        display={type === "LMAUTH" ? "none" : "flex"}
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
      >
        {path?.map((name, index) => {
          const routeTo = `/${path.slice(0, index + 1).join("/")}`;
          const isLast = index === path.length - 1;
          const isFirst = index === 0;

          return isFirst ? (
            <Box />
          ) : isLast ? (
            <BreadcrumbItem key={index}>
              <Text color={"#868686"}> {decodeURI(name)}</Text>
            </BreadcrumbItem>
          ) : (
            <BreadcrumbItem key={index}>
              <BreadcrumbLink onClick={() => navigate(routeTo)}>
                {name}
              </BreadcrumbLink>
            </BreadcrumbItem>
          );
        })}
      </Breadcrumb>
    </div>
  );
}
