import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  LCSAUTH,
  CSAUTH,
  CPAUTH,
  THAUTH,
  FAUTH,
  SMAUTH,
  TCAUTH,
  PAUTH,
  LMAUTH,
  FAAUTH,
  TAUTH,
  ADMINAUTH,
} from "../../routes/RouteData";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  //Hook

  /*
  NOAUTH , 
  LeadCustomerService -> LCSAUTH ,
  CustomerServiceAgent -> CSAUTH ,
  ClientPurchaseRequestagent -> CPAUTH ,
  TechnicalHead -> THAUTH ,
  Foreman -> FAUTH,
  Store Manager -> SMAUTH ,
  TimeCoordinator -> TCAUTH ,
  Purchasers -> PAUTH ,
  LaborManager -> LMAUTH ,
  FinanceAgent -> FAAUTH
  Technician -> TAUTH
  Admin -> ADMINAUTH
  */

  const [type, setType] = useState(null);
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const [checked, setChecked] = useState(false);
  const [Role, setRole] = useState(null);

  //Function
  const login = useCallback((type, token, user) => {
    setType(type);
    if (!type) {
      setRole(null);
    } else if (type == "LCSAUTH") {
      setRole(LCSAUTH);
    } else if (type == "CSAUTH") {
      setRole(CSAUTH);
    } else if (type == "CPAUTH") {
      setRole(CPAUTH);
    } else if (type == "THAUTH") {
      setRole(THAUTH);
    } else if (type == "FAUTH") {
      setRole(FAUTH);
    } else if (type == "SMAUTH") {
      setRole(SMAUTH);
    } else if (type == "TCAUTH") {
      setRole(TCAUTH);
    } else if (type == "PAUTH") {
      setRole(PAUTH);
    } else if (type == "LMAUTH") {
      setRole(LMAUTH);
    } else if (type == "FAAUTH") {
      setRole(FAAUTH);
    } else if (type == "ADMINAUTH") {
      setRole(ADMINAUTH);
    } else {
      setRole([]);
    }
    setToken(token);
    setUser(user);
    localStorage.setItem(
      "aemroAuthData",
      JSON.stringify({
        type,
        token,
        user,
      })
    );
  }, []);

  const logout = useCallback(() => {
    setUser(null);
    setType(null);
    setToken(null);
    localStorage.setItem(
      "aemroAuthData",
      JSON.stringify({
        type: null,
        token: null,
        user: null,
      })
    );
  }, []);

  let auth;

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("aemroAuthData"));

    if (storedData) {
      if (storedData?.token) {
        auth = login(storedData?.type, storedData?.token, storedData?.user);
        if (!storedData?.type) {
          setRole(null);
        }
        if (storedData?.type == "LCSAUTH") {
          setRole(LCSAUTH);
        }
        if (storedData?.type == "CSAUTH") {
          setRole(CSAUTH);
        }
        if (storedData?.type == "CPAUTH") {
          setRole(CPAUTH);
        }
        if (storedData?.type == "THAUTH") {
          setRole(THAUTH);
        }
        if (storedData?.type == "FAUTH") {
          setRole(FAUTH);
        }
        if (storedData?.type == "SMAUTH") {
          setRole(SMAUTH);
        }
        if (storedData?.type == "TCAUTH") {
          setRole(TCAUTH);
        }
        if (storedData?.type == "PAUTH") {
          setRole(PAUTH);
        }
        if (storedData?.type == "LMAUTH") {
          setRole(LMAUTH);
        }
        if (storedData?.type == "FAAUTH") {
          setRole(FAAUTH);
        }
      }
    } else {
      auth = login(null, null, null);
    }
    setChecked(true);
  }, [auth]);

  //Return
  return (
    <AuthContext.Provider
      value={{ type, user, token, checked, login, logout, Role }}
    >
      {children}
    </AuthContext.Provider>
  );
}
