import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  HStack,
  Stack,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import NavBar from "../../navigation/components/NavBar";
import SideBar from "../SideBar";

export default function Home() {
  //Hook
  const [isLargerThan1280] = useMediaQuery("(min-width: 1280px)");

  const btnRef = React.useRef();

  const handleClick = (e) => {
    setClick(!click);
  };
  const [click, setClick] = useState(false);

  //Jsx
  return (
    <Stack
      bg={"#F3F3F3"}
      direction={{ base: "column", lg: "row" }}
      spacing={2}
      position="sticky"
    >
      {isLargerThan1280 && <SideBar />}
      <Box
        minW={{ base: "100%", lg: "100%", xl: "86.4%", "2xl": "84%" }}
        maxW={{ base: "100%", lg: "100%", xl: "86.4%", "2xl": "84%" }}
        h="100vh"
        overflowY={"auto"}
        overflowX={"hidden"}
      >
        <NavBar
          btnRef={btnRef}
          handleClick={handleClick}
          setClick={setClick}
          click={click}
        />
        {!isLargerThan1280 && (
          <Drawer
            isOpen={click}
            placement="left"
            size={"xs"}
            onClose={handleClick}
            finalFocusRef={btnRef}
          >
            <DrawerOverlay />
            <DrawerContent>
              <SideBar />
            </DrawerContent>
          </Drawer>
        )}
        <Outlet />
      </Box>
      {/* <RequestTaskComponent /> */}
    </Stack>
  );
}
