import React, { useContext, useState } from "react";
import { Button } from "@chakra-ui/button";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Input, InputGroup, InputLeftAddon } from "@chakra-ui/input";

import {
  Box,
  Center,
  Divider,
  HStack,
  Img,
  InputRightElement,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Progress,
  Spinner,
  Stack,
  Text,
  Textarea,
  useToast,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import * as Yup from "yup";
import axios from "axios";
import { useMutation } from "react-query";
import { Field, Form, Formik } from "formik";
import { useAuth } from "../../utilities/hooks/auth-hook";
import { useNavigate } from "react-router-dom";
import CustomInput from "./Input/CustomInput";

const PasswordChangeModal = ({ isOpen, onClose }) => {
  const toast = useToast();
  const { token } = useAuth();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const passwordMutation = useMutation(
    async (updatePassword) =>
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + "change-password",
        updatePassword,
        {
          headers,
        }
      ),
    {
      retry: false,
    }
  );

  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };
  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .min(8, "Too Short!")

      .max(35, "Too Long!")

      .required("Required"),
    newPassword: Yup.string()
      .min(8, "Too Short!")

      .max(35, "Too Long!")

      .required("Required"),
    confirmNewPassword: Yup.string()
      .required("Confirm password is a required field")
      .label("Confirm password")
      .test("passwords-match", "Passwords must match", function (value) {
        return this.parent.newPassword === value;
      }),
  });
  const onSubmit = async (values) => {
    const { oldPassword, newPassword, confirmNewPassword } = values;
    try {
      passwordMutation.mutate(
        {
          password: oldPassword,
          new_password: newPassword,
        },
        {
          onSuccess: (responseDataSucc) => {
            onClose();
            toast({
              title: "Updated Password successfully",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
          },
          onError: (responseDataError) => {
            toast({
              title:
                responseDataError?.response?.data?.data ||
                responseDataError?.response?.data?.message ||
                "Error occured during password update",
              description: "Check the password you entered",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          },
        }
      );
      // Redirect the admin to a different page
    } catch (err) {}
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton />
        <Center mb={4}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(values) => {
              return (
                <Form className="company-new-form">
                  <Stack spacing={"25px"} my={"4%"}>
                    <Center fontWeight={"semibold"} fontSize="2xl">
                      {"Change Password"}
                    </Center>
                    <Field name="oldPassword">
                      {({ field, form }) => (
                        <CustomInput
                          field={field}
                          form={form}
                          title={"Old Password"}
                          htmlFor="oldPassword"
                          error={values.errors.oldPassword}
                          touched={values.touched.oldPassword}
                          extra="password"
                          type="password"
                        />
                      )}
                    </Field>
                    <Field name="newPassword">
                      {({ field, form }) => (
                        <CustomInput
                          field={field}
                          form={form}
                          title={"New Password"}
                          htmlFor="newPassword"
                          error={values.errors.newPassword}
                          touched={values.touched.newPassword}
                          extra="password"
                          type="password"
                        />
                      )}
                    </Field>
                    <Field name="confirmNewPassword">
                      {({ field, form }) => (
                        <CustomInput
                          field={field}
                          form={form}
                          title={"Confirm New Password"}
                          htmlFor="confirmNewPassword"
                          error={values.errors.confirmNewPassword}
                          touched={values.touched.confirmNewPassword}
                          extra="password"
                          type="password"
                        />
                      )}
                    </Field>
                    <Button
                      mt={4}
                      colorScheme="blue"
                      spinner={<Spinner size="md" color="white" />}
                      disabled={!(values.isValid && values.dirty)}
                      // isLoading={loginMutation.isLoading}
                      type={!passwordMutation?.isLoading ? "submit" : "button"}
                      isLoading={passwordMutation.isLoading}
                    >
                      {"Change Password"}
                    </Button>
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        </Center>
      </ModalContent>
    </Modal>
  );
};

export default PasswordChangeModal;
