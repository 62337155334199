import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import MaterialTable from "material-table";
import { useAuth } from "../../../../utilities/hooks/auth-hook";
import { tableIcons } from "../../../../utilities/components/MaterialTable";
import PulseLoaderComp from "../../../../utilities/components/queryMutatuinStates/PulseLoaderComp";
import Select from "react-select";
import { BiTransfer } from "react-icons/bi";
import * as Yup from "yup";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { ErrorMessage, Field, Form, Formik } from "formik";
const StockTranfer = () => {
  const { token } = useAuth();
  const queryClient = useQueryClient();

  const toast = useToast();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const [transferData, setTransferData] = useState({
    stockId: null,
    siteFrom: null,
    siteTo: null,
    quantity: null,
    availableQuantity: null,
    site_from_name: null,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [site_id, setSite_id] = useState();

  const transferStockMutation = useMutation(
    async (transferStock) =>
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}transfers`,
        transferStock,
        {
          headers,
        }
      ),
    {
      retry: false,
    }
  );

  const stocksData = useQuery(
    "stocksData",
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}transfer/stocks/${
          site_id ? site_id : 0
        }`,
        // {
        //   params: {
        //     site_id: site_id,
        //   },
        // },
        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
    }
  );

  const tranferHistory = useQuery(
    "tranferHistory",
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}transfers`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
    }
  );
  const sitesData = useQuery(
    "sitesData",
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}site`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
    }
  );

  const loadedColumns = [
    {
      title: "Site name",
      field: "sites.site_name",
    },
    {
      title: "Item name",
      field: "items.item_name",
    },
    {
      title: "Quantity",
      field: "quantity",
    },
    {
      title: "Original price",
      field: "original_price",
    },
    {
      title: "selling price",
      field: "selling_price",
    },
    {
      title: "Transfer",
      render: (rowData) => (
        <Button
          colorScheme="blue"
          onClick={() => {
            onOpen();
            setTransferData({
              stockId: rowData?.id,
              siteFrom: rowData?.sites?.id,
              site_from_name: rowData?.sites?.site_name,
              availableQuantity: rowData?.quantity,
              siteTo: null,
              quantity: null,
            });
          }}
        >
          <BiTransfer />
        </Button>
      ),
    },
  ];
  const stockHistoryColumn = [
    {
      title: "Item name",
      field: "stock.items.item_name",
    },
    {
      title: "Quantity",
      field: "stock.quantity",
    },

    {
      title: "From Site",
      field: "from_site.site_name",
    },
    {
      title: "To Site",
      field: "to_site.site_name",
    },
  ];

  React.useEffect(() => {
    stocksData.refetch();
  }, [site_id]);

  React.useEffect(() => {
    if (
      transferData.siteFrom === transferData.siteTo?.id &&
      transferData.siteTo !== null &&
      transferData.siteFrom !== null
    ) {
      toast({
        title: "Error.",
        description: "Site from and site to cannot be the same",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      setTransferData({
        ...transferData,
        siteTo: null,
      });
    }
  }, [transferData.siteFrom, transferData.siteTo]);
  return (
    <>
      <Box px="2%" w="100%">
        <Stack alignSelf="center">
          <HStack mt={5}>
            <Box flexGrow="1" />

            <Select
              placeholder="Select site"
              isClearable
              styles={{
                control: (base) => ({
                  ...base,
                  paddingLeft: "10px",
                  width: 200,
                  border: "2px solid   #E2E8F0",
                  borderRadius: "0.375rem",
                  color: "#000",
                  _selected: {
                    color: "white",
                    bg: "blue.500",
                  },
                }),
              }}
              options={sitesData?.data?.data}
              getOptionLabel={(option) => option.site_name}
              getOptionValue={(option) => option.id}
              onChange={(e) => {
                setSite_id(e?.id);
              }}
            />
          </HStack>
          {!stocksData?.isLoading ? (
            <>
              <Flex direction="column">
                <Box>
                  {stocksData?.isSuccess ? (
                    <Stack spacing={2}>
                      <Tabs variant="enclosed">
                        <TabList margin={3}>
                          <Tab
                            sx={{
                              border: "1px solid #E2E8F0",
                              borderRadius: "0.375rem",
                              color: "#000",
                              mr: "2",

                              _selected: {
                                color: "white",
                                bg: "blue.500",
                              },
                            }}
                          >
                            Available Stock Items
                          </Tab>
                          <Tab
                            sx={{
                              border: "1px solid #E2E8F0",
                              borderRadius: "0.375rem",
                              color: "#000",
                              ml: "2",

                              _selected: {
                                color: "white",
                                bg: "blue.500",
                              },
                            }}
                          >
                            Stock Transfer History
                          </Tab>
                        </TabList>
                        <TabPanels>
                          <TabPanel>
                            <MaterialTable
                              icons={tableIcons}
                              title={
                                stocksData?.isFetching ? (
                                  <HStack>
                                    <PulseLoaderComp />
                                    <Text> Loading updated data </Text>
                                  </HStack>
                                ) : (
                                  "Available Stock Items"
                                )
                              }
                              columns={loadedColumns}
                              data={stocksData?.data?.data}
                              options={{
                                pageSize: 5,
                                filtering: true,
                              }}
                            />
                          </TabPanel>
                          <TabPanel>
                            <MaterialTable
                              icons={tableIcons}
                              title={
                                tranferHistory?.isFetching ? (
                                  <HStack>
                                    <PulseLoaderComp />
                                    <Text> Loading updated data </Text>
                                  </HStack>
                                ) : (
                                  "Stock Transfer History"
                                )
                              }
                              columns={stockHistoryColumn}
                              data={tranferHistory.data?.data}
                              options={{
                                pageSize: 5,
                                filtering: true,
                              }}
                            />
                          </TabPanel>
                        </TabPanels>
                      </Tabs>
                    </Stack>
                  ) : (
                    <Center>Check your connection and refresh </Center>
                  )}
                </Box>
              </Flex>
            </>
          ) : (
            <PulseLoaderComp h={"90vh"} />
          )}
        </Stack>{" "}
      </Box>
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size={"2xl"}
      >
        <ModalOverlay />
        <ModalContent py={8}>
          <ModalHeader>
            <Text fontWeight="bold" mb="1rem" align={"center"}>
              Transfer stock {"   "}
              <span
                style={{
                  color: "green",
                  textDecoration: "underline",
                }}
              >
                {transferData?.site_from_name}
              </span>
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <Formik
            initialValues={{
              siteTo: transferData?.siteTo || null,
              quantity: transferData?.quantity || null,
            }}
            validationSchema={Yup.object({
              siteTo: Yup.object().required("site is required"),

              quantity: Yup.number("quantity must be a number")
                .required("quantity is required")
                .min(1)
                .max(transferData?.availableQuantity),
            })}
          >
            {(formikProps) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              } = formikProps;

              return (
                <>
                  <ModalBody
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text fontWeight="bold" mb="1rem">
                      Select site to transfer to
                    </Text>

                    <Box as="form" onSubmit={handleSubmit}>
                      <Field
                        name="siteTo"
                        as={Select}
                        placeholder="Select site"
                        styles={{
                          control: (base) => ({
                            ...base,
                            paddingLeft: "10px",
                            width: 200,
                            border: "2px solid   #E2E8F0",
                            borderRadius: "0.375rem",
                            color: "#000",
                            marginBottom: "1rem",
                          }),
                        }}
                        options={sitesData?.data?.data}
                        getOptionLabel={(option) => option.site_name}
                        getOptionValue={(option) => option.id}
                        onChange={(selectedOption) => {
                          console.log("selectedOption", selectedOption);
                          setFieldValue("siteTo", selectedOption);
                          setTransferData({
                            ...transferData,
                            siteTo: selectedOption,
                          });
                        }}
                        onBlur={handleBlur}
                        touched={touched.siteTo}
                        error={errors.siteTo}
                      />
                      {errors.siteTo && touched.siteTo && (
                        <div>{errors.siteTo}</div>
                      )}

                      <Field
                        sx={{
                          width: "100%",
                          border: "2px solid   #E2E8F0",
                          borderRadius: "0.375rem",
                          color: "#000",
                          _selected: {
                            color: "white",
                            bg: "blue.500",
                          },
                        }}
                        name="quantity"
                        as={Input}
                        type="number"
                        placeholder="Quantity"
                        value={values.quantity}
                        onChange={(event) => {
                          setFieldValue("quantity", event.target.value);
                          setTransferData({
                            ...transferData,
                            quantity: event.target.value,
                          });
                        }}
                        onBlur={handleBlur}
                        touched={touched.quantity}
                        error={errors.quantity}
                      />
                      {errors.quantity && touched.quantity && (
                        <Text color="red.500">
                          {errors.quantity && touched.quantity && (
                            <div>{errors.quantity}</div>
                          )}
                        </Text>
                      )}
                    </Box>
                  </ModalBody>

                  <ModalFooter
                    sx={{
                      borderTop: "1px solid #E2E8F0",
                      justifyContent: "space-around",
                      mt: 4,
                    }}
                  >
                    <Button
                      type="button"
                      colorScheme="blue"
                      mr={3}
                      onClick={onClose}
                      disabled={
                        formikProps.isSubmitting ||
                        transferStockMutation.isLoading
                      }
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="solid"
                      loadingText={`Transferring stock `}
                      isLoading={transferStockMutation.isLoading}
                      disabled={
                        transferStockMutation.isLoading ||
                        !formikProps.values.siteTo ||
                        !formikProps.values.quantity > 0 ||
                        formikProps.isSubmitting ||
                        formikProps.errors.quantity ||
                        formikProps.errors.siteTo ||
                        formikProps.errors.quantity >
                          transferData?.availableQuantity ||
                        transferData.siteFrom === transferData.siteTo?.id
                      }
                      onClick={() => {
                        transferStockMutation.mutate(
                          {
                            to_site_id: transferData?.siteTo?.id,
                            from_site_id: transferData?.siteFrom,
                            quantity: transferData?.quantity,
                            stock_id: transferData?.stockId,
                          },
                          {
                            onSuccess: () => {
                              queryClient.invalidateQueries("stocksData");
                              onClose();
                              toast({
                                title: "Stock transfered successfully",
                                status: "success",
                                duration: 9000,
                                isClosable: true,
                              });
                            },
                            onError: (error) => {
                              toast({
                                title: "An error occurred.",
                                description: error?.response?.data?.message,
                                status: "error",
                                duration: 9000,
                                isClosable: true,
                              });
                            },
                          }
                        );
                      }}
                    >
                      Tranfer
                    </Button>
                  </ModalFooter>
                </>
              );
            }}
          </Formik>
        </ModalContent>
      </Modal>
    </>
  );
};

export default StockTranfer;
